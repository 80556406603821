import React from 'react';

import Layout from 'src/components/layout/layout';
import { PrivateRoute } from 'src/components/private-route/private-route';
import SEO from 'src/components/seo';
import Suspension from 'src/components/suspension/suspension';

const SuspensionPointPage: React.FC = () => {
  return (
    <Layout>
      <SEO title="Suspension points" />
      <PrivateRoute>
        <Suspension />
      </PrivateRoute>
    </Layout>
  );
};

export default SuspensionPointPage;
